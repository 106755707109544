import './App.scss';
import {ChakraProvider} from "@chakra-ui/react"
import {Stepper} from './comnponents/Stepper';
import ReactGA from 'react-ga';

function App() {
    ReactGA.initialize('UA-183263461-15');
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <div className="App">
            <ChakraProvider>
                <Stepper/>
            </ChakraProvider>
        </div>
    );
}

export default App;
